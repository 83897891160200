import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-blog',
	templateUrl: './blog.component.html',
	styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

	constructor() { }

	isShow: boolean = true;

	ngOnInit() {
	}
}
