import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
	selector: 'app-blog-single',
	templateUrl: './blog-single.component.html',
	styleUrls: ['./blog-single.component.css']
})
export class BlogSingleComponent implements OnInit, AfterViewInit {

	isShow: boolean = false;

	constructor(private router: Router) {
		this.router.events.subscribe((evt) => {
			if (!(evt instanceof NavigationEnd)) {
				return;
			}
			window.scrollTo(0, 0)
		});
	}

	ngOnInit() {
	}

	loadScript(scriptUrl: string): any {
		return new Promise((resolve, reject) => {
			const scriptElement = document.createElement('script');
			scriptElement.src = scriptUrl;
			scriptElement.onload = resolve;
			document.body.appendChild(scriptElement);
		})
	}

	ngAfterViewInit(): void {
		this.loadScript('../../script/main.js');
		this.loadScript('../../script/scroll.js');
	}

}
