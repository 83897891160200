import { Component, OnInit } from '@angular/core';

import { Contact } from '../contact';
import { ContactService } from '../contact-service.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent implements OnInit {

  constructor(private contactService: ContactService) { }

  ngOnInit() {
  }

  public isSend: boolean = false;

  add(name: string, email: string, subject: string, message: string): void {
    if (!name || !email || !subject || !message) {
      return;
    }
    this.contactService.addContact({ name, email, subject, message } as Contact)
      .subscribe(resp => {
        this.isSend = true;
        setTimeout(function () {
          this.isSend = false;
        }.bind(this), 2500);
      });
  }
}
