import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { BannerComponent } from './banner/banner.component';
import { AboutComponent } from './about/about.component';
import { ServiceComponent } from './service/service.component';
import { ProjectComponent } from './project/project.component';
import { ClientComponent } from './client/client.component';
import { BlogComponent } from './blog/blog.component';
import { GalleryComponent } from './gallery/gallery.component';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { BlogsPageComponent } from './blogs-page/blogs-page.component';
import { BlogSingleComponent } from './blog-single/blog-single.component';

import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


const appRoutes: Routes = [
	{ path: '', component: HomeComponent },
	{ path: 'blog', component: BlogsPageComponent },
	{ path: 'read-blog', component: BlogSingleComponent },
];

@NgModule({
	declarations: [
		AppComponent,
		NavbarComponent,
		BannerComponent,
		AboutComponent,
		ServiceComponent,
		ProjectComponent,
		ClientComponent,
		BlogComponent,
		GalleryComponent,
		ContactComponent,
		HomeComponent,
		BlogsPageComponent,
		BlogSingleComponent,
	],
	imports: [
		BrowserModule,
		RouterModule.forRoot(appRoutes),
		HttpClientModule,
		BrowserAnimationsModule
	],
	providers: [],
	bootstrap: [AppComponent]
})
export class AppModule { }
