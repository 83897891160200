import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css']
})
export class HomeComponent implements AfterViewInit, OnInit {

	constructor() { }

	ngOnInit() {
	}

	loadScript(scriptUrl: string): any {
		return new Promise((resolve, reject) => {
			const scriptElement = document.createElement('script');
			scriptElement.src = scriptUrl;
			scriptElement.onload = resolve;
			document.body.appendChild(scriptElement);
		})
	}

	ngAfterViewInit(): void {
		this.loadScript('../../script/main.js');
		this.loadScript('../../script/scroll.js');
	}
}
